<template>
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3>Selecciona el Tipo de Prenda</h3>
                        <button v-on:click="HideAutomovil = !HideAutomovil" class="btn btn-danger"><i class="fa fa-car"> Agregar Automovil</i></button>
                        <button v-on:click="HidePrendaG = !HidePrendaG" class="btn btn-danger"><i class="fa fa-clock-o"> Agregar Prenda Generica</i></button>
                    </div>
                    <div class="card-body">
                    <agregarautomovil-component
                        v-if="!HideAutomovil"
                    ></agregarautomovil-component>
                    <agregarprendagen-component
                        v-if="!HidePrendaG"
                    ></agregarprendagen-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
        name: 'agregarprenda-component',
        data() {
            return {
                HideAutomovil: true,
                HidePrendaG: true,
            }
        },
        methods: {

        },
    }
</script>
