/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

Vue.component('buscador-component', require('./components/BuscadorPrincipal.vue').default);

Vue.component('buscadorprestamos-component', require('./components/BuscadorPrestamos.vue').default);

Vue.component('buscadordistribuidor-component', require('./components/BuscarDistribuidor.vue').default);

Vue.component('agregarprestamos-component',require('./components/AgregarPrestamo.vue').default)

Vue.component('agregarprenda-component', require('./components/AgregarPrenda.vue').default);

Vue.component('agregarautomovil-component',require('./components/AgregarAutomovil.vue').default)

Vue.component('agregarprendagen-component',require('./components/AgregarPrendaDif.vue').default)

Vue.component('prestamosclientes-component', require('./components/PrestamosClientesComponent.vue').default);

Vue.component('prestamosclientesservicios-component',require('./components/PrestamosClientesServices.vue').default)

const app = new Vue({
    el: '#app',
    data: {
        HideClientes: true,
        HideContratos: true,
        HideDistribuidores: true,
        HidePrestamos: true,
        HideAgregar: true,
        HidePrendas: true,
        savingSuccessful: false
    }
});
