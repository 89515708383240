<template>
    <div class="card">
        <div class="card-body">
            <h3>No. Prestamo {{PrestamosIndividuale.id_prestamos}}</h3>
            <h3>Fecha Prestamo: {{PrestamosIndividuale.FechaPrestamo}}</h3>
            <h3>Tipo Prenda: {{PrestamosIndividuale.prenda}}</h3>
            <table class="table table-bordred table-striped w-100">
                <thead>
                <th>Cantidad Prestada</th>
                <th>Intereses</th>
                <th>Plazo</th>
                <th>Adeudo Actual</th>
                <th>Intereses Moratorios</th>
                <th>Acción</th>
                </thead>
                <tbody>
                <td>$ {{PrestamosIndividuale.cantidadpres}}</td>
                <td>$ {{PrestamosIndividuale.intereses}} </td>
                <td>{{PrestamosIndividuale.plazo}} </td>
                <td>$ {{PrestamosIndividuale.totalpagar}} </td>
                <td>$ {{PrestamosIndividuale.totalinteres}}</td>
                <td><a class="btn bg-primary btn-primary">Desglose</a></td>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: ['PrestamosIndividuale'],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            editPrestamosIndividuale: true
        }
    },
    mounted() {
        console.log('Its Working.')
    },
    methods: {
        onClickEdit() {
            this.editPrestamosIndividuale = false;
        },
        deletePrestamosIndividuale(id) {
            Swal.fire({
                title: 'Estas seguro?',
                text: "Esta acción es irreversible!",
                icon: 'Precuación',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
            }).then((result) => {

                if (result.value) {
                    //Send Request to server
                    axios.delete(`/PrestamosIndividuale/${this.PrestamosIndividuale.id}`).then(() => {
                        this.$emit('delete');
                    });
                }

            })
        }
    }
}
</script>

<style scoped>

</style>
