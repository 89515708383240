<template>
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header">
                         <h3>Prestamos Vigentes</h3>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered table-striped">
                                <thead>
                                <th>Saldo Corriente</th>
                                <th>Saldo Vencido</th>
                                <th>Saldo Prestamo</th>
                                <th>Total Intereses</th>
                                </thead>
                                <tbody>
                                <td><h4 v-for="(TotalAdeudo) in TotalAdeudos" class="text-success" v-if="TotalAdeudo.StatusdelPrestamo === 'En Tiempo'">$ {{TotalAdeudo.PagoTotal}}</h4></td>
                                <td><h4 v-for="(TotalAdeudo) in TotalAdeudos" class="text-danger" v-if="TotalAdeudo.StatusdelPrestamo === 'Atrasado'">$ {{TotalAdeudo.PagoTotal}}</h4></td>
                                <td><h4 v-for="(VerDeuda) in VerDeudas" class="text-dark">$ {{VerDeuda.TotalDeuda}}</h4></td>
                                <td><h4 v-for="(VerDeuda) in VerDeudas" class="text-danger">$ {{VerDeuda.TotalIntereses}} </h4></td>
                                </tbody>
                            </table>
                        </div>
                    </div>
                <prestamosclientes-component
                    v-for="(PrestamosIndividuale, index) in PrestamosIndividuales"
                    :key="PrestamosIndividuale.id_prestamos"
                    :PrestamosIndividuale="PrestamosIndividuale"
                    @update="updatePrestamosIndividuale(index, ...arguments)"
                    @delete="deletePrestamosIndividuale(index)">
                </prestamosclientes-component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            PrestamosIndividuales: [],
            VerDeudas: [],
            TotalAdeudos: []
        }
    },
    mounted() {
        let id_cliente = window.location.href.split('/').pop();
        axios.get('/api/PrestamosIndividuales/'+id_cliente).then((response) => {
                this.PrestamosIndividuales = response.data;
            }
        );
        axios.get('/api/VerDeudas/'+id_cliente).then((response) => {
                this.VerDeudas = response.data;
            }
        );
        axios.get('/api/TotalAdeudos/'+id_cliente).then((response) => {
                this.TotalAdeudos = response.data;
            }
        );
    },
    methods: {
        updatePrestamosIndividuale(index, PrestamosIndividuales){
            this.PrestamosIndividuales[index] = PrestamosIndividuale;
        },
        deletePrestamosIndividuale(index){
            this.PrestamosIndividuales.splice(index, 1);
        }

    }
}
</script>

<style scoped>

</style>

