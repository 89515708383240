<template>
    <form action="#" class="form newtopic" v-on:submit.prevent="registrarPrendaGenerica" v-if="!savingSuccessful">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="Cantidad">Limite de Credito</label>
                    <input type="text" class="form-control" v-model="limitcredit" aria-describedby="limitcredit" placeholder="$">
                </div>
            </div>

            <div class="col-md-4">
                <div class="form-group">
                    <label for="Tipo Prenda">Tipo de Prenda</label>
                    <input type="text" class="form-control" v-model="tipoprenda" aria-describedby="tipoprenda" placeholder="Tipo de Prenda">
                </div>

            </div>

            <div class="col-md-4">
                    <div class="form-group">
                        <label for="observaciones">Observaciones / Caracteristicas</label>
                        <input type="text" class="form-control" v-model="observaciones" aria-describedby="observaciones" placeholder="Observaciones">
                    </div>
           </div>


            <div class="col-sm-12">
                <h4>Antes de dar click en Registrar revisa que todos los datos de la Prenda esten correctos</h4>
                <button type="submit" class="btn btn-primary w-100" style="border-radius: 20px">Registrar</button>
            </div>

        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            limitcredit: '',
            tipoprenda: '',
            observaciones: '',
            savingSuccessful: false
        }
    },
    mounted() {
        let id_cliente = window.location.href.split('/').pop();
    },
    methods: {
        registrarPrendaGenerica() {
            const headers = {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": this.csrf
            };
            const params = {
                limitcredit: this.limitcredit,
                tipoprenda: this.tipoprenda,
                observaciones: this.observaciones,
                id_cliente: window.location.href.split('/').pop()
            };
            axios.put('/agregarnuevaprenda', params, {headers : headers}).then(response => {
                console.log(response);
                window.location.href = '/verclientes/' +window.location.href.split('/').pop();
                document.querySelector('.success-message').innerHTML = `Prenda Agregada Correctamente`;
            });
        },
    },
}

</script>
