<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="search-box">
                <div class="col-md-12">
                    <input class="search-input bg-light"
                           type="text"
                           name="q"
                           placeholder="Escribe el Número de cliente o su Nombre"
                           v-model="query"
                           @input="search">
                    <br/>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <ul class="result-list"
                    :class="resultsVisibility">
                    <li v-for="cliente in clientes"
                        class="result-item">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <table class="table table-bordered table-striped w-100">
                                    <thead>
                                    <tr>
                                        <th class="w-10">No.Cliente</th>
                                        <th class="w-40">Nombre</th>
                                        <th class="w-20">Ver / Editar</th>
                                        <th class="w-20">Agregar Contrato</th>
                                        <th class="w-20">Calificacion</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td class="w-10"><h4><b>{{cliente.id}}</b></h4></td>
                                        <td class="w-40"><h4><b>{{cliente.completo}}</b></h4></td>
                                        <td class="w-20"><a :href="'verclientes/'+cliente.id" class="btn btn-success"><i class="fa fa-user fa-2x"></i><br/> Ver <br/>Cliente</a></td>
                                        <td class="w-20"><a :href="'addcontract/'+cliente.id" class="btn btn-success"><i class="fa fa-edit fa-2x"></i> <br><span>Agregar<br/> Contrato</span></a></td>
                                        <td class="w-20"></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </li>

                </ul>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            query: '',
            clientes: []
        }
    },
    computed: {
        resultsVisibility() {
            return (this.query.length > 0) ? 'show' : 'hide';
        }
    },
    methods: {
        search() {
            axios.post('/post/clientes',
                {
                    q: this.query
                }).then(res => {
                this.clientes = res.data.clientes;
            }).catch(error => {
                console.log(error.response);
            })
        }

    }
}
</script>

<style scoped>
* {
    outline: none;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    margin: 25px;
    font-family: san-serif;
}

::-webkit-scrollbar{
    width: 10px;
}
::-webkit-scrollbar-track{
    background: #000000;
}
::-webkit-scrollbar-thumb{
    background: #888;
}
::-webkit-scrollbar-thumb:hover{
    background: #555;
}
.search-box{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.search-input{
    width: auto;
    height: 30px;
    border-right: 10px;
    border: 0;
    background: #eeeeee;
    padding: 10px 20px;
    font-size: 18px;
    outline: none;
}
.result-list.show{
    width: auto;
    max-height: 600px;
    overflow-y: auto;
    list-style: none;
    background: #fff;
    padding: 0;
    top: 40px;
    border-radius: 10px;
    box-shadow: 1px 2px 8px 0px #b5b5b5;
    margin: 7px 0px;
}
.result-list.hide{
    display: none;
}
.result-item{
    border-bottom: 1px solid #ececec;
}
.result-link{
    text-decoration: none;
    color: #333;
    display: block;
    padding: 10px 15px;
}
.result-link{
    background: #f9f9f9;
}
.result-title{
    font-size: 20px;
    font-weight: 600;
}
.result-content{
    font-size: 18px
}

.tb {
    display: table;
    width: 100%;
}

.td {
    display: table-cell;
    vertical-align: middle;
}
input,
button {
    color: #000000;
    font-family: Nunito;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
}

#cover {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 550px;
    padding: 35px;
    margin: -83px auto 0 auto;
    background-color: #ff7575;
    border-radius: 20px;
    box-shadow: 0 10px 40px #ff7c7c, 0 0 0 20px #ffffffeb;
    transform: scale(0.6);
}

form {
    height: 96px;
}

input[type="text"] {
    width: 100%;
    height: 96px;
    font-size: 20px;
    line-height: 1;
}

input[type="text"]::placeholder {
    color: #e16868;
}

#s-cover {
    width: 1px;
    padding-left: 35px;
}


</style>
