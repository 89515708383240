<template>
    <form action="#" class="form newtopic" v-on:submit.prevent="registrarPrendaAuto" v-if="!savingSuccessful">
<div class="row justify-content-center">
    <div class="col-md-4">
        <div class="form-group">
            <label for="rfc">Marca</label>
            <select class="form-control" v-model="marca">
                <option value="No seleccionado">Seleccione una marca</option>
                <option value="Alfa Romeo">Alfa Romeo</option>
                <option value="Aston Martin">Aston Martin</option>
                <option value="Audi">Audi</option>

                <option value="Autovaz">Autovaz</option>
                <option value="Bentley">Bentley</option>
                <option value="Bmw">Bmw</option>
                <option value="Cadillac">Cadillac</option>

                <option value="Caterham">Caterham</option>
                <option value="Chevrolet">Chevrolet</option>
                <option value="Chrysler">Chrysler</option>
                <option value="Citroen">Citroen</option>

                <option value="Daihatsu">Daihatsu</option>
                <option value="Dodge">Dodge</option>
                <option value="Ferrari">Ferrari</option>
                <option value="Fiat">Fiat</option>

                <option value="Ford">Ford</option>
                <option value="Honda">Honda</option>
                <option value="Hummer">Hummer</option>
                <option value="Hyundai">Hyundai</option>

                <option value="Isuzu">Isuzu</option>
                <option value="Jaguar">Jaguar</option>
                <option value="Jeep">Jeep</option>
                <option value="Kia">Kia</option>

                <option value="Lamborghini">Lamborghini</option>
                <option value="Lancia">Lancia</option>
                <option value="Land Rover">Land Rover</option>
                <option value="Lexus">Lexus</option>

                <option value="Lotus">Lotus</option>
                <option value="Maserati">Maserati</option>
                <option value="Mazda">Mazda</option>
                <option value="Mercedes Benz">Mercedes Benz</option>

                <option value="MG">MG</option>
                <option value="Mini">Mini</option>
                <option value="Mitsubishi">Mitsubishi</option>
                <option value="Morgan">Morgan</option>

                <option value="Nissan">Nissan</option>
                <option value="Opel">Opel</option>
                <option value="Peugeot">Peugeot</option>
                <option value="Porsche">Porsche</option>

                <option value="Renault">Renault</option>
                <option value="Rolls Royce">Rolls Royce</option>
                <option value="Rover">Rover</option>
                <option value="Saab">Saab</option>

                <option value="Seat">Seat</option>
                <option value="Skoda">Skoda</option>
                <option value="Smart">Smart</option>
                <option value="Ssangyong">Ssangyong</option>

                <option value="Subaru">Subaru</option>
                <option value="Suzuki">Suzuki</option>
                <option value="Tata">Tata</option>
                <option value="Toyota">Toyota</option>

                <option value="Volkswagen">Volkswagen</option>
                <option value="Volvo">Volvo</option>
            </select>
        </div>

        <div class="form-group">
            <label for="direccion">Modelo</label>
            <input type="text" class="form-control" v-model="modelo" placeholder="Modelo">
        </div>

        <div class="form-group">
            <label for="Año">Año</label>
            <select v-model="anio" class="form-control">
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                <option value="2009">2009</option>
                <option value="2008">2008</option>
                <option value="2007">2007</option>
                <option value="2006">2006</option>
                <option value="2005">2005</option>
                <option value="2004">2004</option>
                <option value="2003">2003</option>
                <option value="2002">2002</option>
                <option value="2001">2001</option>
                <option value="2000">2000</option>
            </select>
        </div>
    </div>

    <div class="col-md-4">
        <div class="form-group">
            <label for="Titulo">Número de Titulo</label>
            <input type="text" class="form-control" v-model="titulo" aria-describedby="titulo" placeholder="Titulo">
        </div>

        <div class="form-group">
            <label for="NVIN">Número VIN</label>
            <input type="text" class="form-control" v-model="vin" aria-describedby="email" placeholder="VIN">
        </div>

        <div class="form-group">
            <label for="placa">Placa</label>
            <input type="text" class="form-control" v-model="placa" aria-describedby="placa" placeholder="Placa">
        </div>
    </div>

    <div class="col-sm-4">
        <div class="form-group">
            <label for="millas">Millas</label>
            <input type="text" class="form-control" v-model="millas" aria-describedby="millas" placeholder="Millas">
        </div>

        <div class="form-group">
            <label for="Cantidad">Limite de Credito</label>
            <input type="text" class="form-control" v-model="limitcredit" aria-describedby="limitcredit" placeholder="$">
        </div>
    </div>
    <div class="col-sm-12">
        <h4>Antes de dar click en Registrar revisa que todos los datos de la Prenda esten correctos</h4>
        <button type="submit" class="btn btn-primary w-100" style="border-radius: 20px">Registrar</button>
    </div>

</div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    limitcredit: '',
                    marca: '',
                    modelo: '',
                    anio: '',
                    titulo: '',
                    vin: '',
                    placa: '',
                    millas: '',
                    savingSuccessful: false,
                    currentImageExterior: undefined,
                    previewImageExterior: undefined,
                    currentImageInterior: undefined,
                    previewImageInterior: undefined,
                    currentImageTablero: undefined,
                    previewImageTablero: undefined,
                    progress: 0,
                    message: "",
                    imageInfos: [],
            }
        },
        mounted() {
            let id_cliente = window.location.href.split('/').pop();
        },
        methods: {
            selectImageExterior(event) {
             this.currentImageExterior = event.target.files[0];
            },
            selectImageInterior(event) {
            this.currentImageInterior = event.target.files[0];
            },
            selectImageTablero(event) {
            this.currentImageTablero = event.target.files[0];
            },
            registrarPrendaAuto() {
               const headers = {
                   "Content-Type": "application/json",
                   "X-CSRF-TOKEN": this.csrf
               };
               const params = {
                   limitcredit: this.limitcredit,
                   marca: this.marca,
                   modelo: this.modelo,
                   anio: this.anio,
                   titulo: this.titulo,
                   vin: this.vin,
                   placa: this.placa,
                   millas: this.millas,
                   id_cliente: window.location.href.split('/').pop(),
               };
                axios.put('/agregarnuevoauto', params, {headers : headers}).then(response => {
                    console.log(response);
                    window.location.href = '/verclientes/' +window.location.href.split('/').pop();
                });
            },
        },
    }

</script>
