<template>
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3>Prendas Disponibles para Prestamo</h3>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Imagen Prenda</th>
                                    <th>Tipo Prenda</th>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Año</th>
                                    <th>Limite de Crédito</th>
                                    <th>Status</th>
                                    <th>Ver / Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="PrendasIndividuale in PrendasIndividuales">
                                <td>
                                        <button class="btn btn-primary" data-toggle="modal" data-target="#modalAutomovil" @click="getAutomovil(PrendasIndividuale.id_prendas)">
                                            <i class="fa fa-car"> Ver Imagenes</i>
                                        </button>
                                </td>
                                <td>{{PrendasIndividuale.prenda}}</td>
                                <td>{{PrendasIndividuale.marca}}</td>
                                <td>{{PrendasIndividuale.modelo}}</td>
                                <td>{{PrendasIndividuale.anio}}</td>
                                <td>{{PrendasIndividuale.limitcredit}}</td>
                                <td><button class="btn btn-success" v-if="PrendasIndividuale.status === 'Sin Prestamos'"><a :href="'../addcontract/'+PrendasIndividuale.idcliente" class="text-white"><i class="fa fa-money-o">Agregar Prestamo</i></a></button>
                                    <button class="btn btn-warning" v-if="PrendasIndividuale.status === 'En Empeño' && PrendasIndividuale.restcredit > 0"><a :href="'../addcontract/'+PrendasIndividuale.idcliente" class="text-white"><i class="fa fa-money-o">Agregar Prestamo</i></a></button>
                                    <button class="btn btn-danger" v-if="PrendasIndividuale.status === 'En Empeño' && PrendasIndividuale.restcredit <= 0"><i class="fa fa-money-o">Sin Límite de Credito</i></button>
                                </td>
                                <td>

                                        <a :href="'../editarprenda/'+PrendasIndividuale.idcliente+'/'+PrendasIndividuale.id" class="btn btn-primary text-white"><i class="fa fa-edit"> Ver / Editar</i></a>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-justify">
            <div class="col-md-10">
                <h4><button class="btn btn-success">Verde</button>No tiene creditos activos y tiene credito libre</h4>
                <h4><button class="btn btn-warning">Amarillo</button>Cuenta con prestamos activos y credito disponible.</h4>
                <h4><button class="btn btn-danger">Rojo</button>Sin crédito para más prestamos</h4>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    data(){
        return {
            PrestamosIndividuales: [],
            VerDeudas: [],
            TotalAdeudos: [],
            PrendasIndividuales: [],
            TotalPrendas: [],
            TotalPrendasIndividuales: []
        }
    },
    mounted() {
        let id_cliente = window.location.href.split('/').pop();
        axios.get('/api/PrestamosIndividuales/'+id_cliente).then((response) => {
                this.PrestamosIndividuales = response.data;
            }
        );
        axios.get('/api/VerDeudas/'+id_cliente).then((response) => {
                this.VerDeudas = response.data;
            }
        );
        axios.get('/api/TotalAdeudos/'+id_cliente).then((response) => {
                this.TotalAdeudos = response.data;
            }
        );
        axios.get('/api/PrendasIndividuales/'+id_cliente).then((response) => {
                this.PrendasIndividuales = response.data;
            }
        );
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
